import axios from 'axios'
import request from '@/utils/request'
export function getProjectRegistrationList() {
  return axios({
    baseURL: process.env.VUE_APP_BASE_API,
    url: 'pay/web/projectRegistration/getProjectRegistrationList',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
export function loginForRegistration(data) {
  return axios({
    baseURL: process.env.VUE_APP_BASE_API,
    url: 'pay/login',
    method: 'post',
    data
  })
}
export function checkCreateRegistrationOrder(proId) {
  return axios({
    baseURL: process.env.VUE_APP_BASE_API,
    url: 'pay/web/projectRegistration/checkCreateRegistrationOrder/' + proId,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
export function createRegistrationOrder(data) {
  return request({
    url: 'pay/web/projectRegistration/createRegistrationOrder/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
export function getUnpaidOrder(proId) {
  return request({
    url: 'pay/web/projectRegistration/getUnpaidOrder/' + proId,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
export function getOrderInfoByNameListId(id) {
  return request({
    url: 'pay/web/projectRegistration/getOrderInfoByNameListId/' + id,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
// 查询系统消息列表
export function getSchooPageList(pageCurrent, pageSize) {
  return axios({
    baseURL: process.env.VUE_APP_BASE_API,
    url: 'pay/web/projectRegistration/getSchooPageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'pageCurrent': pageCurrent,
      'pageSize': pageSize
    }
  })
}
export function register(data) {
  return axios({
    baseURL: process.env.VUE_APP_BASE_API,
    url: 'pay/web/projectRegistration/register',
    method: 'post',
    data
  })
}
// 普通注册
export function userRegister(data) {
  return request({
    url: 'pay/web/register/userRegister',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

export function toPayOrderTrade(data) {
  return request({
    url: 'pay/web/third/toPayOrderTrade/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

export function getWeXinJsapiTicket(appId, agentId, webUrl) {
  return request({
    url: '/pay/open/weChat/getWeXinJsapiTicket',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      'appId': appId,
      'webUrl': webUrl,
      'agentId': agentId
    }
  })
}

