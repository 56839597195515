<template>
  <div id="signRegister">
    <div class="main">
      <div>
        <!-- 头部地理位置和搜索框 -->
        <div class="head">
          <!-- 顶部NavBar 开始 -->
          <van-nav-bar
            :title="$t('signReg.title')"
            :left-text="$t('main.return')"
            left-arrow
            style="background-color: rgba(255,255,255,0);"
            @click-left="onClickLeft"
          />
        </div>
      </div>
      <div class="outboxstyle">
        <van-form :model="normalRegisterForm">
          <div>
            <!-- <div class="paymonry">用户信息</div> -->

            <div class="form-top" style="overflow: hidden;border-radius: 8px;box-shadow: 0 0 6px #dde9f3;">

              <van-field
                required
                trigger="onChange"
                :value="schoolName"
                :label="$t('register.school')"
                :placeholder="$t('register.choose-school')"
                class="picker"
                @click="schoolShow = true"
              />
              <van-popup v-model="schoolShow" position="bottom">
                <van-picker
                  show-toolbar
                  :columns="schoolList"
                  @confirm="onConfirmSchool"
                  @cancel="schoolShow = false"
                />
              </van-popup>
              <van-field
                v-model="normalRegisterForm.accountNum"
                required
                type="text"
                :label="$t('register.account')"
                :placeholder="$t('register.fill-account')"
                style="padding-top: 12px;padding-bottom: 12px;"
              />
              <van-field
                v-model="normalRegisterForm.password"
                required
                type="password"

                :label="$t('register.psw')"
                :placeholder="$t('register.psw-input')"
                style="padding-top: 12px;padding-bottom: 12px;"
              />
              <van-field
                v-model="normalRegisterForm.passwordConfirm"
                required
                type="password"
                :label="$t('register.psw-confirm')"
                :placeholder="$t('register.psw-input2')"
                style="padding-top: 12px;padding-bottom: 12px;"
              />
              <van-field
                v-model="normalRegisterForm.phone"
                required
                type="text"

                :label="$t('register.tel')"
                :placeholder="$t('register.tel-input')"
                style="padding-top: 12px;padding-bottom: 12px;"
              />
            </div>
            <!-- 按钮组 -->
            <div class="btn-group">
              <van-button
                block
                type="info"
                native-type="submit"
                class="btn-class submit"
                @click="onSubmit"
              >
                {{ $t('register.title') }}
              </van-button>
              <van-button
                block
                type="warning"
                native-type="submit"
                class="btn-class submit register-btn"
                @click="onClickLeft"
              >
                {{ $t('signReg.btn') }}
              </van-button>
            </div>
          </div>
        </van-form>
      </div>
      <!-- 回到顶部按钮 -->
      <v-top />
    </div>
  </div>
</template>
<script>
import { getSchooPageList, register } from '@/api/registration/projectForRegistration'
// import store from '@/store'
import { Toast } from 'vant'
export default {
  name: 'SignRegister',
  data() {
    return {
      schoolName: '',
      schoolList: [],
      schoolShow: false,
      // 普通注册form
      normalRegisterForm: {
        accountNum: '',
        passwordConfirm: '',
        password: '',
        phone: '',
        schoolCode: '',
        schoolId: ''
      }
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
    // document.querySelector('body').setAttribute('style', 'background: #fff')
  },
  beforeDestroy() {
    document.querySelector('body').setAttribute('style', 'background: #fff')
  },
  mounted() {
    this.getSchooPageList()
  },
  methods: {
    //   获取学校
    getSchooPageList() {
      getSchooPageList(
        1,
        100
      ).then(response => {
        // 给list新增text属性 schoolName   只有vant这个框架只接收text
        const tempList = response.data.data.records
        for (let i = 0; i < tempList.length; i++) {
          this.$set(tempList[i], 'text', tempList[i].schoolName)
        }
        this.schoolList = tempList
      }).catch(err => {
        Toast.fail(err.message)
      })
    },
    // 选择学校
    onConfirmSchool(item) {
      this.normalRegisterForm.schoolCode = item.schoolCode
      this.normalRegisterForm.schoolId = item.id
      this.schoolName = item.schoolName
      this.schoolShow = false
    },
    schoolTest() {
      const value = this.normalRegisterForm.schoolCode
      const defaultObj = {
        'boolean': true,
        'message': ''
      }
      if (value === '') {
        console.log('value', value)
        const obj = {
          'boolean': false,
          'message': this.$t('msg.chooseSchool')
        }
        return obj
      }
      return defaultObj
    },
    accountNumTest() {
      const value = this.normalRegisterForm.accountNum
      const defaultObj = {
        'boolean': true,
        'message': ''
      }
      if (value === '') {
        const obj = {
          'boolean': false,
          'message': this.$t('register.fill-account')
        }
        return obj
      }
      return defaultObj
    },
    passwordTest() {
      const value = this.normalRegisterForm.password
      // 判断字符串是否为数字和字母和字符组合
      const re = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&\*\+,._\(\)\{\}\\?<>;':"-])[\da-zA-Z~!@#$%^&\*\+,._\(\)\{\}\\?<>;':"-]{6,}$/
      const defaultObj = {
        'boolean': true,
        'message': ''
      }
      if (value === '') {
        const obj = {
          'boolean': false,
          'message': this.$t('msg.writePwd')
        }
        return obj
      } else if (value.length < 6 || value.length > 20) {
        const obj = {
          'boolean': false,
          'message': this.$t('msg.pwdLength')
        }
        return obj
      } else if (!re.test(value)) {
        const obj = {
          'boolean': false,
          'message': this.$t('msg.pwdSize')
        }
        return obj
      }
      return defaultObj
    },
    passwordConfirmTest() {
      const value = this.normalRegisterForm.passwordConfirm
      const defaultObj = {
        'boolean': true,
        'message': ''
      }
      if (value !== this.normalRegisterForm.password) {
        const obj = {
          'boolean': false,
          'message': this.$t('msg.pwdSame')
        }
        return obj
      }
      return defaultObj
    },
    phoneTest() {
      const value = this.normalRegisterForm.phone
      const phoneRe = /^1(3|4|5|7|8|9)\d{9}$/
      const defaultObj = {
        'boolean': true,
        'message': ''
      }
      if (value === '') {
        const obj = {
          'boolean': false,
          'message': this.$t('register.tel-input')
        }
        return obj
      } else if (!phoneRe.test(value)) {
        const obj = {
          'boolean': false,
          'message': this.$t('msg.truePhone')
        }
        return obj
      }
      return defaultObj
    },
    onSubmit() {
      const schoolPass = this.schoolTest()
      const accountNumPass = this.accountNumTest()
      const passwordPass = this.passwordTest()
      const passwordConfirmPass = this.passwordConfirmTest()
      const phonePass = this.phoneTest()
      const testArray = [schoolPass, accountNumPass, passwordPass, passwordConfirmPass, phonePass]
      for (let i = 0; i < testArray.length; i++) {
        if (testArray[i].boolean === false) {
          Toast.fail(testArray[i].message)
          return false
        }
      }
      var vo = {}
      vo.dataSource = this.$store.state.settings.dataSource
      vo.schoolCode = this.normalRegisterForm.schoolCode
      vo.accountNum = this.normalRegisterForm.accountNum
      vo.phone = this.normalRegisterForm.phone
      vo.schoolId = this.normalRegisterForm.schoolId
      vo.password = this.$md5(this.normalRegisterForm.password)
      register(vo).then(response => {
        Toast.success(response.message)
        setTimeout(() => {
          this.$router.push('/signUpLogin')
        }, 1000)
      }).catch(err => {
        Toast.fail(err.message)
      })
    //   if (
    //     this.form.oldPass === '' ||
    //     this.form.newPass === '' ||
    //     this.form.confirmPass === ''
    //   ) {
    //     Toast.fail('原/新密码不能为空')
    //     return false
    //   }
    //   if (this.form.newPass !== this.form.confirmPass) {
    //     Toast.fail(P两次密码不一致')
    //     return false
    //   }
    //   if (this.form.newPass.length < 6 || this.form.newPass.length > 20) {
    //     Toast.fail('长度在 6 到 20 个字符')
    //     return false
    //   }
    },
    // 返回
    onClickLeft() {
      this.$router.push({ path: 'signUpLogin' })
    }
  }
}
</script>
<style scoped lang="scss">
.form-top{
    padding-top: 6px ;
    padding-bottom: 7px ;
    background: #fff;
}
.paymonry{
  border-left: 4px #1989fa solid;
  padding-left: 10px;
  // margin: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}
.register-btn{
  background: #fff;
  color: #2F9FF2;
  border: 1px solid #2F9FF2;
}
.btn-class{
  border-radius: 6px;
}
.btn-group{
  margin-top:40px;
  .btn-class{
    margin: 10px 0;
  }
  .btn-text{
    color: #2F9FF2;
  }
}
#signRegister {
    min-height: 667px;
  .van-cell {
  line-height: 30px;
  }
  .van-icon__image {
    width: 40px;
    height: 30px;
}
  position: relative;
  background-color: #f5f5f5;
  .head {
    height: 84px;
    width: 100%;
    background:linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  padding:20px 30px;
  // padding: 0px 10px 10px 10px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 100px;
  width: 100%;
  height: 100%;
}
.boxstyle {
  margin: 10px 15px 0 15px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px;
  }
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  position: fixed;
  bottom:0;
  left: 0;
  width: 100%;
  background: #ffffff;
  height: 80px;
  margin-top:20px;
}
.bottombutton{
  margin: 20px;
  width:90%;
  height: 40px;
}
.van-cell__value {
  span{
    color: #333333;
  }
}
#totalpay {
  .van-cell__value {
  span{
    color: #3BA4F2;
  }
}
}
</style>
<style>
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
</style>
<style lang="scss" scoped>
.main{
  margin-bottom:50px;
}
.box{
  width: 86%;
  margin:0 auto;
  padding:0 10%;
  height:120px;
  background:linear-gradient(135deg,rgba(55,147,229,1) 0%,rgba(61,105,199,1) 100%);
  border-radius:12px;
}
.img-box{
width:60px;
height:60px;
border:1px solid #ffffff;
background:#fff;
border-radius:50% ;
margin-top:32px;
}
.text-box{
margin-top:20px;

  p {
    font-size:14px;
    line-height: 30px;
    height: 20px;
    color: rgba(216,235,244,1);
    span{
    color: #FFFFFF;
    }
  }
}
</style>
